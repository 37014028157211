import '../../App.scss';
import '../../css/modals.scss';
import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import 'magicsuggest/magicsuggest.js';
import 'jquery-ui/ui/widgets/autocomplete';
import React, { useEffect, useState, useContext, createRef, useRef } from 'react';
import BookingGroupsSelector from '../BookingGroupsSelector';
import BookingRow from '../BookingRow';
import BookingAddonRow from '../BookingAddonRow';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import TabHeader from '../TabHeader';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import SingleSelectDropdown from '../SingleSelectDropdown';
import BookingPaymentInput from '../BookingPaymentInput';
import classnames from 'classnames';
import { useParams, useNavigate } from "react-router-dom";
import { Button, Row, Container, Alert, Col, Table, Image } from 'react-bootstrap';
import { serverFetch, serverPost, serverPatch, notifyEvent } from '../../helpers/server';
import {
    BaseContext,
    getDefaultColor,
    currencyFormat,
    getFilteredPaymentMethods,
    cyrb53,
    hasAccess,
    conciseTimeDisplay,
    getNameForDayOfWeek,
    getAccessibleVenues,
    getEventTypesForVenue,
    getPaymentProcessorName,
    sleep, isFullAdmin, GetShortedName
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require("lodash");
var cache = require('js-cache');
var calcCostCache = new cache();

function AddOrEditBookingModal(props) {
    const navigate = useNavigate();
    const { getApiUrl, settings, isLoggedIn, userInfo } = useContext(BaseContext);
    const { facilityLink } = useParams();
    const [venues, setVenues] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [originalVenueId, setOriginalVenueId] = useState(null);
    const [selectedVenueIds, setSelectedVenueIds] = useState([]);
    const [partnerVenueSelected, setPartnerVenueSelected] = useState(false);
    const [partnerSitesSelected, setPartnerSitesSelected] = useState([]);
    const [addons, setAddons] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [repeatType, setRepeatType] = useState(null);
    const [repeatEndType, setRepeatEndType] = useState(null);
    const [currentTab, setCurrentTab] = useState("details");
    const [showOverlap, setShowOverlap] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [initialFields, setInitialFields] = useState({});
    const formRef = createRef();
    const paymentRef = createRef();
    const rowRefs = useRef([]);
    const addonsRowRefs = useRef([]);
    const groupSelectorRef = createRef();
    const [hasBookingsInPast, setHasBookingsInPast] = useState(false);
    const [hasDifferentStandardRate, setHasDifferentStandardRate] = useState(false);
    const [targetDate, setTargetDate] = useState(null);
    const [calcCost, setCalcCost] = useState(null);
    const [numberOfBookingRows, setNumberOfBookingRows] = useState(1);
    const [numberOfAddons, setNumberOfAddons] = useState(0);
    const [primaryGroup, setPrimaryGroup] = useState(null);
    const [useCustomGroupRate, setUseCustomGroupRate] = useState(false);
    const [pairedVenueIds, setPairedVenueIds] = useState([]);
    const [pairedVenues, setPairedVenues] = useState([]);
    const [newGroupsCount, setNewGroupsCount] = useState(0);
    const [overlapOptions, setOverlapOptions] = useState([]);
    const [error, setError] = useState(null);
    const [groupError, setGroupError] = useState(null);
    const [isPaid, setIsPaid] = useState(false);
    const [invoice, setInvoice] = useState(null);
    const [agreement, setAgreement] = useState(null);
    const [notifyUser, setNotifyUser] = useState(false);
    const [logs, setLogs] = useState([]);
    const [editingBookingId, setEditingBookingId] = useState(null);
    const [showAddons, setShowAddons] = useState(false);
    const [repeatChangeOption, setRepeatChangeOption] = useState("no");
    const [originalPaymentMethod, setOriginalPaymentMethod] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [copiedBooking, setCopiedBooking] = useState(null);
    const [datePickerMarginLeft, setDatepickerMarginLeft] = useState(0);
    const [numberOfRepeatRows, setNumberOfRepeatRows] = useState(1);
    const calcCostKeyInProgress = useRef(null);
    const { t } = useTranslation('common');
    const allowAddons = false;

    useEffect(() => {
        setCopiedBooking(props.copiedBooking);
    }, [props.copiedBooking]);

    useEffect(() => {
        if (!props.show) {
            return;
        }
        if (!_.isNil(props.booking)) {
            const originalVenueId = props.booking.extendedProps.venueId;
            setOriginalVenueId(originalVenueId);
            let originalVenue = null;
            if (originalVenueId) {
                originalVenue = _.find(venues, (v) => v.id === originalVenueId);
            }
            const start = props.booking.start || props.booking.startTimeLocal;
            let end = null;
            if (!_.isNil(props.booking.endTimeLocal)) {
                end = props.booking.endTimeLocal;
            } else {
                end = props.booking.end;
                if (originalVenue && String(originalVenue.isPadding) === String(2)) {
                    let bpadding = props.booking.extendedProps.bpadding;
                    end = moment(end).subtract(bpadding, "minutes").format();
                }
            }
            let existingPaymentMethod = props.booking.extendedProps.paymentMethod;
            if (_.includes(['stripe', 'helcim', 'beanstream', 'payeezy', 'payTrace', 'bambora', 'sportspay'], existingPaymentMethod)) {
                existingPaymentMethod = "paidCreditOnline"
            }
            let newPaymentMethod = props.newPaymentMethod || props.booking.extendedProps.paymentMethod;
            if (_.includes(['stripe', 'helcim', 'beanstream', 'payeezy', 'payTrace', 'bambora', 'sportspay'], newPaymentMethod)) {
                newPaymentMethod = "paidCreditOnline"
            }
            let bookingRateType = props.booking.extendedProps.rateType;
            let standardRateChanged = false;
            if (bookingRateType === "standard" && !props.booking.extendedProps.inException) {
                const currentStandardRate = getDefaultRateForVenue(originalVenueId)
                const existingBookingRate = props.booking.extendedProps.rate;
                if (existingBookingRate !== currentStandardRate) {
                    bookingRateType = "custom";
                    standardRateChanged = true;
                }
            }
            setHasDifferentStandardRate(standardRateChanged);
            setInitialFields({
                "0": {
                    venueId: originalVenueId,
                    start: moment(start),
                    end: moment(end),
                    startTime: moment(start).format("HH:mm:00"),
                    endTime: moment(end).format("HH:mm:00"),
                    rate: props.booking.extendedProps.rate,
                    rateType: bookingRateType,
                    taxRatePercent: props.booking.extendedProps.taxPercentage,
                    bPadding: props.booking.extendedProps.bPadding || 0,
                    eventName: props.booking.extendedProps.eventName,
                    description: props.booking.extendedProps.description,
                    eventTypeId: props.booking.extendedProps.eventTypeId,
                    bid: props.booking.extendedProps.bid || props.booking.extendedProps.id
                },
                addons: _.map(props.booking.extendedProps.addons, (addon) => {
                    return {
                        ...addon,
                        addonId: addon.id,
                        count: addon.hours
                    }
                }),
                groups: _.map(props.booking.extendedProps.bookingGroups, g => {
                    const nameParts = g.userFullName.split(" ");
                    return {
                        ...g,
                        user: {
                            fullName: g.userFullName,
                            firstName: nameParts[0],
                            lastName: nameParts.length > 1 ? nameParts[1] : "",
                            email: g.userEmail,
                        }
                    }
                }),
                color: props.booking.backgroundColor || props.booking.color || getDefaultColor(t),
                notes: props.booking.extendedProps.note,
                paymentMethod: newPaymentMethod,
                notifyUser: originalVenue && originalVenue.notifyCustomerDefault,
                repeatConfig: props.booking.extendedProps.repeatInfo ? {
                    ...props.booking.extendedProps.repeatInfo,
                    type: props.booking.extendedProps.repeatInfo.repeatType,
                    endsAfter: props.booking.extendedProps.repeatInfo.ends === 2 ? props.booking.extendedProps.repeatInfo.occurrence: 0,
                    endDate: props.booking.extendedProps.repeatInfo.ends === 3 ? props.booking.extendedProps.repeatInfo.endDate: 0,
                    on: _.map(props.booking.extendedProps.repeatInfo.repeatOn.split(","), (o) => parseInt(o.trim())),
                } : {
                    startDate: moment(start).format("YYYY-MM-DD"),
                    on: [moment(start).day()],
                    endsAfter: 2
                },
                repeatChanges: props.booking.extendedProps.repeatInfo && {
                    modifyRepeatConfig: {
                        option: "no",
                        dateStartTimeLocal: props.booking.extendedProps.repeatInfo && moment(props.booking.extendedProps.repeatInfo.startDate).format("YYYY-MM-DD"),
                        dateEndTimeLocal: props.booking.extendedProps.repeatInfo && moment(props.booking.extendedProps.repeatInfo.computedEndDate).format("YYYY-MM-DD"),
                    }
                },
                updateInvoice: true,
                updateAgreement: true,
                pushToPartner: true
            });
            setOriginalPaymentMethod(existingPaymentMethod);
            setPaymentMethod(newPaymentMethod);
            setEditingBookingId(props.booking.extendedProps.bid || props.booking.extendedProps.id);
            setCurrentTab("details");
            setRepeatType(props.booking.extendedProps.repeatInfo && props.booking.extendedProps.repeatInfo.repeatType);
            setTargetDate(moment(start).format("YYYY-MM-DD"));
            setRepeatEndType((props.booking.extendedProps.repeatInfo && props.booking.extendedProps.repeatInfo.ends === 3) ? "date": "counter");
            updateNumberOfBookingRows(1);
            setLogs(props.booking.extendedProps.logs);
            setIsEditing(true);
            setIsPaid(props.booking.extendedProps.isPaid);
            setTimeout(() => {
                onFieldChange("0.rateType", props.booking.extendedProps.rateType)
            }, 200);
            setInvoice(props.booking.extendedProps.invoice);
            setAgreement(props.booking.extendedProps.agreement);
            setShowAddons(!_.isEmpty(props.booking.extendedProps.addons));
            setNumberOfAddons(props.booking.extendedProps.addons.length);
            if (props.booking.extendedProps.bookingGroups && props.booking.extendedProps.bookingGroups.length > 0) {
                setPrimaryGroup(props.booking.extendedProps.bookingGroups[0]);
            }
        } else {
            if (_.isEmpty(props.selectionInfo) || _.isNil(props.selectionInfo)) {
                return;
            }
            const originalVenueId = props.selectionInfo && props.selectionInfo.venue && props.selectionInfo.venue.id;
            setOriginalVenueId(originalVenueId);
            setHasDifferentStandardRate(false);
            updateNumberOfBookingRows(1);
            const eTypes = getEventTypesForVenue(eventTypes, originalVenueId);
            let eid = null;
            if (!_.isEmpty(eTypes)) {
                eid = eTypes[0].id;
            }
            const ifields = {
                "0": {
                    venueId: originalVenueId,
                    start: moment(props.selectionInfo.start),
                    end: moment(props.selectionInfo.end),
                    startTime: moment(props.selectionInfo.start).format("HH:mm:00"),
                    endTime: moment(props.selectionInfo.end).format("HH:mm:00"),
                    rate: getDefaultRateForVenue(originalVenueId),
                    rateType: "standard",
                    taxRatePercent: getTaxRateForVenue(originalVenueId),
                    bPadding: getDefaultPaddingForVenue(originalVenueId),
                    eventTypeId: eid
                },
                addons: [],
                groups: [],
                color: getDefaultColor(t),
                repeatConfig: {
                    startDate: moment(props.selectionInfo.start).format("YYYY-MM-DD"),
                    on: [moment(props.selectionInfo.start).day()],
                    endsAfter: 2
                },
                pushToPartner: true
            }
            if (!_.isNil(copiedBooking)) {
                ifields.notes = copiedBooking.extendedProps.note;
                ifields.color = copiedBooking.backgroundColor;
                ifields.groups = copiedBooking.extendedProps.bookingGroups;
                ifields.paymentMethod = copiedBooking.extendedProps.paymentMethod;
                ifields["0"].rateType = copiedBooking.extendedProps.rateType;
                ifields["0"].bPadding = copiedBooking.extendedProps.bPadding;
                ifields["0"].taxRatePercent = copiedBooking.extendedProps.taxPercentage;
                ifields["0"].rate = copiedBooking.extendedProps.rate;
                ifields["0"].eventTypeId = copiedBooking.extendedProps.eventTypeId;
                ifields["0"].eventName = copiedBooking.extendedProps.eventName;
                ifields["0"].description = copiedBooking.extendedProps.description;
                let copiedHours = moment(copiedBooking.end).diff(moment(copiedBooking.start));
                ifields["0"].end = ifields["0"].start.add(copiedHours);
                if (copiedBooking.extendedProps.bPadding > 0) {
                    ifields["0"].end = ifields["0"].end.subtract(copiedBooking.extendedProps.bPadding, 'minutes')
                }
                ifields["0"].endTime = ifields["0"].end.format("HH:mm:00");
                if (!_.isEmpty(copiedBooking.extendedProps.bookingGroups)) {
                    setPrimaryGroup(copiedBooking.extendedProps.bookingGroups[0]);
                }
            }
            setInitialFields(ifields)
            setRepeatType("none");
            setTargetDate(moment(props.selectionInfo.start).format("YYYY-MM-DD"));
            setRepeatEndType("counter");
            setCurrentTab("details");
            if (originalVenueId) {
                serverFetch(getApiUrl(`/venues/${originalVenueId}/multi_bookings`)).then((res) => {
                    const venueIds = _.map(_.filter(res, r => r.selected && r.venueId !== originalVenueId), (v) => v.venueId);
                    setPairedVenueIds(venueIds);
                })
            }
            setIsEditing(false);
            setIsPaid(false);
            setShowAddons(false);
            setNumberOfAddons(0);
        }
    }, [props.selectionInfo, props.booking, props.show, venues]);

    useEffect(() => {
        if (!props.show) {
            setInitialFields({
                "0": {
                    eventTypeId: null
                }
            });
            updateNumberOfBookingRows(0);
            setHasBookingsInPast(false);
            setShowOverlap(false);
            setOverlapOptions([]);
            setEventTypes([]);
            setInvoice(null);
            setOriginalVenueId(null);
            setPairedVenueIds([]);
            setError(null);
            setGroupError(null);
            setPrimaryGroup(null);
            setPaymentMethod(null);
            setOriginalPaymentMethod(null);
            setCalcCost(null);
            setNotifyUser(false);
            setNumberOfRepeatRows(1);
        }
    }, [props.show]);

    useEffect(() => {
        $(document).click(function(event) {
            if (!$("#pop-date").hasClass("hasDatepicker")) {
                return;
            }
            var $target = $(event.target);
            let isInside = false;
            if ($target.closest('.ui-datepicker').length > 0 || $target.closest('.ui-widget-header').length > 0) {
                isInside = true;
            }
            if(!isInside) {
                $('#pop-date').datepicker( "destroy" );
            } else {
                event.preventDefault();
                event.stopPropagation();
            }
        });
    }, []);

    useEffect(() => {
        // Handler to call on window resize
        $( document ).ready(function() {
            function handleResize() {
                if ($('.booking-date-wrapper').first().length === 0) {
                    return;
                }
                let marginLeft = ($('.booking-date-wrapper').first().width()-221)/2;
                let contentLeft = $('.booking-date-wrapper').first().position().left;
                setDatepickerMarginLeft(contentLeft + marginLeft);
            }

            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
            handleResize();
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        });
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('/venues'), { skipAddingCategory: true }).then((res) => {
            let vs = getAccessibleVenues(res, userInfo);
            setVenues(vs);
        })

        if (isLoggedIn && hasAccess("booking", userInfo, "update", originalVenueId)) {
            serverFetch(getApiUrl('/payment_methods')).then((res) => {
                setPaymentMethods(res);
            });
        }
    }, [isLoggedIn, originalVenueId]);

    useEffect(() => {
        if (isLoggedIn && hasAccess("settings", userInfo, "update", originalVenueId)) {
            serverFetch(getApiUrl('/addons')).then((res) => {
                setAddons(res);
            })
        }
    }, [])

    useEffect(() => {
        const vss = [];
        _.each(pairedVenueIds, (pvi, i) => {
            const vs = _.find(venues, (v) => v.id === pvi);
            if (vs) {
                vss.push(vs)
            }
        })
        setPairedVenues(vss);
    }, [pairedVenueIds, venues]);

    useEffect(() => {
        if (_.isNil(originalVenueId)) {
            setEventTypes([]);
            return;
        }
        serverFetch(getApiUrl('/event_types', { venueIds: _.map(venues, v => v.id) }), { skipAddingCategory: true }).then((res) => {
            if (isEditing || true) {
                if (_.has(initialFields, "0")) {
                    const currentEventType = _.find(res, (et) => String(et.id) === String(initialFields["0"].eventTypeId));
                    const currentVenueId = String(initialFields["0"].venueId);
                    if (!_.isNil(currentEventType) && (String(currentEventType.rinkId) !== String(currentVenueId) && !_.includes(currentEventType.inheritedToVenueIds, parseInt(currentVenueId)))) {
                        const newEventType = _.find(res, (et) => String(et.eventType) === currentEventType.eventType && String(et.rinkId) === String(currentVenueId));
                        if (!_.isNil(newEventType)) {
                            setInitialFields(prevInitialFields => {
                                const newInitialFields = {...prevInitialFields};
                                newInitialFields["0"].eventTypeId = newEventType.id;
                                return newInitialFields;
                            });
                        } else {
                            resetEventTypes("0.");
                        }
                    }
                }
            }
            setEventTypes(res);
        });
    }, [originalVenueId, venues]);

    const onDateClick = (event) => {
        var $target = $(event.target);
        event.preventDefault();
        event.stopPropagation();
        if ($target.closest('.ui-datepicker').length > 0) {
            return;
        }
        if ($("#pop-date").hasClass("hasDatepicker")) {
            $('#pop-date').datepicker( "destroy" );
        } else {
            $('#pop-date').datepicker({
                defaultDate: moment(targetDate).toDate(),
                autoSize: true,
                changeYear: true,
                changeMonth: true,
                yearRange: "c-10:c+10",
                hideIfNoPrevNext: true,
                dateFormat: "yy-mm-dd",
                onSelect: (value) => {
                    $('#pop-date').datepicker( "destroy" );
                    setTargetDate(value);
                }
            });
        }
    }

    const updateNumberOfBookingRows = (num) => {
        rowRefs.current = _.range(num).map((r) => createRef());
        setNumberOfBookingRows(num);
    }

    const updateNumberOfAddonRows = (num) => {
        addonsRowRefs.current = _.range(num).map((r) => createRef());
        setNumberOfAddons(num);
    }

    useEffect(() => {
        updateSelectedVenueIds();
    }, [numberOfBookingRows]);

    useEffect(() => {
        if (primaryGroup) {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                if (primaryGroup.customerColor) {
                    newInitialFields['color'] = primaryGroup.customerColor || getDefaultColor(t);
                }
                if (primaryGroup.rate != null) {
                    newInitialFields['useCustomGroupRate'] = true;
                    _.each(_.range(numberOfBookingRows), (r) => {
                        newInitialFields[String(r)].rateType = "custom"
                    });
                } else {
                    newInitialFields['useCustomGroupRate'] = false;
                }
                if (primaryGroup.cTax != null) {
                    _.each(_.range(numberOfBookingRows), (r) => {
                        newInitialFields[String(r)].taxRatePercent = primaryGroup.cTax;
                    });
                }
                return newInitialFields;
            });
        } else {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                _.each(_.range(numberOfBookingRows), (r) => {
                    newInitialFields[String(r)].taxRatePercent = getTaxRateForVenue(newInitialFields[String(r)].venueId);
                });
                return newInitialFields;
            });
        }
    }, [primaryGroup]);

    useEffect(() => {
        updateCalcCostIfNeeded();
        updateHasBookingsInPast();
    }, [initialFields])

    useEffect(() => {
        let isAttachedToPartner = false;
        let attachedSites = [];
        _.each(selectedVenueIds, (vid) => {
            const vv = _.find(venues, (v) => String(v.id) === String(vid));
            if (vv && !_.isEmpty(vv.partnerMappings)) {
                isAttachedToPartner = true;
                _.each(vv.partnerMappings, pm => {
                    attachedSites.push(pm.siteId);
                })
            }
        })
        setPartnerVenueSelected(isAttachedToPartner);
        setPartnerSitesSelected(attachedSites);
    }, [selectedVenueIds, venues]);

    const updateHasBookingsInPast = () => {
        if (isEditing) {
            setHasBookingsInPast(false);
            return;
        }
        if (!formRef.current) {
            return;
        }
        const formData = formRef.current.getFormData();
        const hasData = _.every(_.range(numberOfBookingRows), (r) => {
            return _.has(formData, String(r));
        });
        if (!hasData) {
            return;
        }

        const hasPastBookings = !_.every(_.range(numberOfBookingRows), (val, a, r, w) => {
            return moment(targetDate + " " + formData[String(val)].startTime).isAfter(moment());
        });
        setHasBookingsInPast(hasPastBookings);
    }

    const getTaxRateForVenue = (venueId) => {
        if (primaryGroup && primaryGroup.cTax !== null) {
            return primaryGroup.cTax;
        }
        return settings.tax1;
    }

    const getDefaultRateForVenue = (venueId) => {
        if (_.isNil(venueId)) {
            return String(0);
        } else {
            const svenue = _.find(venues, v => v.id === venueId);
            return svenue && String(svenue.defaultRate);
        }
    }

    const getDefaultPaddingForVenue = (venueId) => {
        if (_.isNil(venueId)) {
            return 0;
        } else {
            const svenue = _.find(venues, v => String(v.id) === String(venueId));
            if (!svenue || String(svenue.defaultPadding) === String(1)) {
                // Weird special case, return 0
                return 0;
            } else {
                return svenue.defaultPadding;
            }
        }
    }

    const accessLevelOptions = [
        { 'value': 1, 'label': 'Full Admin Access' },
        { 'value': 2, 'label': 'View Only Access' },
        { 'value': 3, 'label': 'Custom' },
    ];

    const repeatTypeOptions = [
        { 'value': "none", 'label': 'None' },
        { 'value': "week", 'label': 'Weekly' },
        { 'value': "day", 'label': 'Daily' },
        { 'value': "month", 'label': 'Monthly' },
        { 'value': "specific", 'label': 'Specific Dates' },
    ];

    const repeatOnOptions = [
        { value: 1, label: "Monday" },
        { value: 2, label: "Tuesday" },
        { value: 3, label: "Wednesday" },
        { value: 4, label: "Thursday" },
        { value: 5, label: "Friday" },
        { value: 6, label: "Saturday" },
        { value: 0, label: "Sunday" },
    ];

    const onFieldChange = (name, value) => {
        if (name === "groups") {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["groups"] = value;
                return newInitialFields;
            });
            if (_.isEmpty(value)) {
                setPrimaryGroup(null);
            } else {
                setPrimaryGroup(value[0]);
            }
        } else if (name === "repeatConfig.type") {
            setRepeatType(value);
            if (value === "week") {
                setInitialFields(prevInitialFields => {
                    const newInitialFields = {...prevInitialFields};
                    newInitialFields.repeatConfig = newInitialFields.repeatConfig || {};
                    return newInitialFields;
                });
            }
        } else if (name === "repeatChanges.modifyRepeatConfig.option") {
            setRepeatChangeOption(value);
        } else if (name === "cc_name") {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields.cc_name = value;
                return newInitialFields;
            });
        } else if (name === "paymentMethod") {
            setPaymentMethod(value);
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields.paymentMethod = value;
                return newInitialFields;
            });
        } else if (name === "useCustomGroupRate") {
            setUseCustomGroupRate(value);
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["useCustomGroupRate"] = value;
                _.each(_.range(numberOfBookingRows), (r) => {
                    newInitialFields[String(r)].rateType = value === true ? "custom": "standard";
                });
                return newInitialFields;
            });
        } else if (name.endsWith(".rateType")) {
            if (value === "standard" || value === "flat") {
                setUseCustomGroupRate(false);
                setInitialFields(prevInitialFields => {
                    const newInitialFields = {...prevInitialFields};
                    newInitialFields["useCustomGroupRate"] = false;
                    return newInitialFields;
                });
            }
        } else if (name.endsWith(".venueId")) {
            updateSelectedVenueIds();
            const index = name.split(".")[0];
            const existingValue = initialFields && initialFields["0"] && initialFields["0"].venueId;
            if (!existingValue || (existingValue !== value)) {
                setInitialFields(prevInitialFields => {
                    const newInitialFields = {...prevInitialFields};
                    newInitialFields[index]["bPadding"] = getDefaultPaddingForVenue(value);
                    return newInitialFields;
                });
            }
        } else if (name.endsWith(".eventTypeId")) {
            if (isEditing) {
                if (_.has(initialFields, "0")) {
                    const currentEventType = _.find(eventTypes, (et) => String(et.id) === String(value));
                    const currentVenueId = String(initialFields["0"].venueId);
                    if (!_.isNil(currentEventType) && String(currentEventType.rinkId) !== String(currentVenueId)) {
                        const newEventType = _.find(getEventTypesForVenue(eventTypes, currentVenueId), (et) => String(et.eventType) === currentEventType.eventType);
                        if (!_.isNil(newEventType)) {
                            setInitialFields(prevInitialFields => {
                                const newInitialFields = {...prevInitialFields};
                                newInitialFields["0"].eventTypeId = newEventType.id;
                                return newInitialFields;
                            });
                        } else {
                            resetEventTypes("0.");
                        }
                    }
                }
            }
        } else if (_.includes(["color", "notes", "updateReason", "paymentReference"], name)) {
            setInitialFields(prevInitialFields => {
                prevInitialFields[name] = value;
                return prevInitialFields;
            });
        } else if (name === "notifyUser") {
            setNotifyUser(value);
            setInitialFields(prevInitialFields => {
                prevInitialFields[name] = value;
                return prevInitialFields;
            });
        } else if (name === "repeatConfig.endDate") {
            // Repeat end
            setRepeatEndType("date");
        } else if (name === "repeatConfig.endsAfter") {
            // Repeat end
            setRepeatEndType("counter");
        }
        _.each(rowRefs.current, (ref) => {
            if (ref.current) {
                ref.current.onFieldChange(name, value);
            }
        })
        _.each(addonsRowRefs.current, (ref) => {
            if (ref.current) {
                ref.current.onFieldChange(name, value);
            }
        })
        if (paymentRef.current) {
            paymentRef.current.onFieldChange(name, value);
        }
        if (_.isNil(targetDate)) {
            return;
        }
        if (groupSelectorRef.current) {
            groupSelectorRef.current.onFieldChange(name, value);
        }
        updateCalcCostIfNeeded();
    }

    const resetEventTypes = (prefix, newRinkId) => {
        const index = prefix.split(".")[0];
        setInitialFields(prevInitialFields => {
            const newInitialFields = {...prevInitialFields};
            if (_.has(newInitialFields, String(index))) {
                const currentEventType = _.find(eventTypes, (et) => String(et.id) === String(newInitialFields[String(index)].eventTypeId));
                if (!_.isNil(newRinkId) && !_.isNil(currentEventType)) {
                    const newEventType = _.find(getEventTypesForVenue(eventTypes, newRinkId), (et) => String(et.eventType) === String(currentEventType.eventType));
                    if (!_.isNil(newEventType)) {
                        setInitialFields(prevInitialFields => {
                            const newInitialFields = {...prevInitialFields};
                            newInitialFields[String(index)].eventTypeId = newEventType.id;
                            return newInitialFields;
                        });
                    } else {
                        newInitialFields[String(index)].eventTypeId = null;
                    }
                } else {
                    newInitialFields[String(index)].eventTypeId = null;
                }
            }
            return newInitialFields;
        });
    }

    const updateSelectedVenueIds = () => {
        if (!formRef.current) {
            return;
        }
        const formData = formRef.current.getFormData();
        const hasData = _.every(_.range(numberOfBookingRows), (r) => {
            return _.has(formData, String(r));
        });
        if (!hasData) {
            return;
        }
        setSelectedVenueIds(_.map(_.range(numberOfBookingRows), (r) => formData[String(r)].venueId));
    }

    const updateCalcCostIfNeeded = async () => {
        if (!formRef.current) {
            return;
        }
        const formData = formRef.current.getFormData();
        const hasData = _.every(_.range(numberOfBookingRows), (r) => {
            return _.has(formData, String(r));
        });
        const hasAddonData = _.every(_.range(numberOfAddons), (r) => {
            return _.has(formData["addons"], String(r));
        });
        if (!hasData || !hasAddonData) {
            return;
        }
        const hasValidData = _.every(_.range(numberOfBookingRows), (r) => {
            if (_.isNil(formData[String(r)].eventTypeId)) {
                return false;
            }
            return true;
        })
        if (!hasValidData) {
            return;
        }

        const bookingRequests = _.map(_.range(numberOfBookingRows), (r) => {
            let startTime = moment(targetDate + " " + formData[String(r)].startTime);
            let endTime = moment(targetDate + " " + formData[String(r)].endTime);
            if (endTime.isBefore(startTime)) {
                endTime = moment(targetDate + " " + formData[String(r)].endTime).add(1, 'day');
            }
            return {
                startTimeLocal: startTime.utcOffset(0, true).format(),
                endTimeLocal: endTime.utcOffset(0, true).format(),
                rateType: formData[String(r)].rateType,
                venueId: formData[String(r)].venueId,
                rateAmount: formData[String(r)].rate || "0",
                taxRatePercent: formData[String(r)].taxRatePercent || "0",
                bPadding: formData[String(r)].bPadding,
                eventTypeId: formData[String(r)].eventTypeId,
            }
        });
        const addonRequests = _.map(_.range(numberOfAddons), (r) => {
            return {
                hours: formData["addons"][String(r)].count,
                addonId: formData["addons"][String(r)].addonId,
                rateType: "standard"
            }
        })

        let ccPayMethod = null;
        if (formData.paymentMethod === "paidCreditOnline") {
            ccPayMethod = getPaymentProcessorName(settings.paymentProcessor).toLowerCase();
        }

        const calcData = {
            primaryGroupId: (formData.groups && !_.isEmpty(formData.groups)) ? formData.groups[0].gid : null,
            bookingRequests: _.filter(bookingRequests, (r) => r !== null),
            addonRequests: _.filter(addonRequests, (r) => r !== null),
            useCustomGroupRate: formData.useCustomGroupRate,
            paymentMethod: ccPayMethod !== null ? ccPayMethod : formData.paymentMethod,
        }

        const calcKey = cyrb53(JSON.stringify(calcData))
        if (calcCostKeyInProgress.current === calcKey) {
            // A request is already in progress with the same params so don't do anything.
            return;
        }
        calcCostKeyInProgress.current = calcKey;
        const existingValue = calcCostCache.get(calcKey);
        if (existingValue) {
            setCalcCost(existingValue);
            calcCostKeyInProgress.current = null;
        } else {
            serverPost(getApiUrl("/bookings/calc_cost"), calcData).then((res) => {
                if (res) {
                    calcCostCache.set(calcKey, res, 30000);
                    if (calcCostKeyInProgress.current === calcKey) {
                        calcCostKeyInProgress.current = null;
                        setCalcCost(res);
                    } else {
                        // Old result
                    }
                } else {
                    calcCostKeyInProgress.current = null;
                }
            });
        }
    }

    useEffect(() => {
        if (calcCost) {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["costDescription"] = `${ currencyFormat(calcCost.preTax) } + ${ currencyFormat(calcCost.taxAmount) }` + ((_.includes(settings.creditCardFeeUsage, "booking") && calcCost.fees) ? ` + ${ currencyFormat(calcCost.fees) }` : ``) + ` = ${ currencyFormat(calcCost.total) }`;
                _.range(numberOfBookingRows).map((r, i) => {
                    newInitialFields[String(r)].rate = String((calcCost.calc[String(r)] && calcCost.calc[String(r)].rate) || 0);
                })

                _.range(numberOfAddons).map((r, i) => {
                    newInitialFields["addons"][String(r)].rate = calcCost.addonCalc[String(r)].rate;
                    newInitialFields["addons"][String(r)].rateType = calcCost.addonCalc[String(r)].rateType;
                    newInitialFields["addons"][String(r)].preTax = calcCost.addonCalc[String(r)].preTax;
                    newInitialFields["addons"][String(r)].taxAmount = calcCost.addonCalc[String(r)].taxAmount;
                    newInitialFields["addons"][String(r)].total = calcCost.addonCalc[String(r)].total;
                })

                return newInitialFields;
            })
        }
    }, [calcCost]);

    const onError = async (response, errorMessage) => {
        if (!_.isEmpty(errorMessage.overlap)) {
            Notification.ModalShow("Please select options for the following overlaps");
            setShowOverlap(true);
            setOverlapOptions(errorMessage.overlap);
            setCurrentTab("overlap");
            return false;
        }  else if (!_.isEmpty(errorMessage.credit)) {
            Notification.ModalShow("There is insufficient credit to pay for this booking.");
            return false;
        } else {
            let message = null;
            if (errorMessage.bPadding) {
                message = "Some settings were changed (potentially in a different window). Please refresh the page and try this again."
            }
            setError({
                message: message,
                ...errorMessage,
                "0": {
                    ...errorMessage
                }
            })
        }
    }

    const onUserGroupError = async (response, errorMessage) => {
        console.log("User group error " + JSON.stringify(errorMessage));
        setGroupError(errorMessage.groupName);
    }

    const addBooking = async (data) => {
        const formData = formRef.current.getFormData();
        let bookingPaymentMethod = null;
        if (paymentRef.current) {
            bookingPaymentMethod = await paymentRef.current.getPaymentMethod(formData);
            if (!bookingPaymentMethod) {
                return;
            } else if (bookingPaymentMethod.errors) {
                setError(bookingPaymentMethod.errors);
                return;
            }
        }

        setGroupError(null);
        const newGroups = _.filter(data.groups, (g) => g.__isNew__);
        let allGroups = _.filter(data.groups, (g) => !g.__isNew__);
        if (!_.isEmpty(newGroups)) {
            const newlyCreatedGroups = await Promise.all(
                _.map(newGroups, async (gr) => {
                    const [firstName, ...parts] = gr.userFullName.split(' ');
                    const lastName = parts.join(" ");
                    const userData = {
                        email: !_.isEmpty(gr.userEmail) ? gr.userEmail : null,
                        firstName: firstName,
                        lastName: lastName,
                        phoneNumber: gr.userPhone,
                    };
                    const user = await serverPost(getApiUrl("/users"), userData, {}, onUserGroupError);
                    if (!user) {
                        return null;
                    }

                    const groupData = {
                        userId: user.id,
                        groupName: gr.groupName || gr.value,
                        customerTypeId: gr.customerTypeId,
                    };
                    const group = serverPost(getApiUrl("/user_groups"), groupData, {}, onUserGroupError);
                    return group;
                })
            );
            const didAllSucceed = _.every(newlyCreatedGroups, (g) => !_.isNil(g));
            if (!didAllSucceed) {
                return;
            }
            allGroups = _.concat(allGroups, newlyCreatedGroups);

            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["groups"] = allGroups;
                return newInitialFields;
            });
            _.each(allGroups, (r) => {
                r.name = r.groupName;
                r.userEmail = r.user && r.user.email;
                r.gid = r.id;
            })
            setPrimaryGroup(allGroups[0]);
        }

        const bookings = _.map(_.range(numberOfBookingRows), (r) => {
            let startTimeLocal = moment(targetDate + " " + formData[String(r)].startTime).utcOffset(0, true);
            let endTimeLocal = moment(targetDate + " " + formData[String(r)].endTime).utcOffset(0, true);
            if (endTimeLocal <= startTimeLocal) {
                // Add a day if the start time is after end time.
                endTimeLocal.add(1, 'days');
            }
            return {
                startTimeLocal: startTimeLocal.format(),
                endTimeLocal: endTimeLocal.format(),
                rateType: formData[String(r)].rateType,
                venueId: formData[String(r)].venueId,
                rate: formData[String(r)].rate,
                bPadding: formData[String(r)].bPadding,
                eventTypeId: formData[String(r)].eventTypeId,
                description: formData[String(r)].description,
                eventName: formData[String(r)].eventName,
                taxRate: formData[String(r)].taxRatePercent || "0",
                id: initialFields[String(r)].bid
            }
        });
        const addons = _.map(_.range(numberOfAddons), (r) => {
            return {
                addonId: formData["addons"][String(r)].addonId,
                hours: formData["addons"][String(r)].count,
                rate: formData["addons"][String(r)].rate,
                preTax: formData["addons"][String(r)].preTax,
                taxAmount: formData["addons"][String(r)].taxAmount || "0",
                total: formData["addons"][String(r)].total,
                rateType: "standard",
                taxRatePercent: settings.tax1
            }
        });

        const repeatConfig = (!isEditing && data.repeatConfig && data.repeatConfig.type !== "none") ? {
            type: data.repeatConfig.type,
            interval: data.repeatConfig.interval,
            on: data.repeatConfig.on,
            endsAfter: repeatEndType === "counter" ? data.repeatConfig.endsAfter: null,
            endDate: repeatEndType === "date" ? data.repeatConfig.endDate: null,
            startDate: targetDate,
        }: null
        if (repeatConfig && repeatConfig.type === "specific") {
            let repeatDates = [];
            _.each(_.range(numberOfRepeatRows), r => {
                if (data.repeatConfig[`dates_${r}`]) {
                    repeatDates.push(data.repeatConfig[`dates_${r}`])
                }
            })
            repeatConfig.specificDates = repeatDates;
        }

        let repeatChanges = data.repeatChanges;
        if (!repeatChanges && isEditing) {
            if (data.repeatConfig && data.repeatConfig.type === "specific") {
                let repeatDates = [];
                _.each(_.range(numberOfRepeatRows), r => {
                    if (data.repeatConfig[`dates_${r}`]) {
                        repeatDates.push(data.repeatConfig[`dates_${r}`])
                    }
                })
                data.repeatConfig.specificDates = repeatDates;
            }
            repeatChanges = data.repeatConfig.type !== "none" ? {
                repeatConfig: data.repeatConfig
            } : null;
        }
        
        const partnerMappingExists = !_.isNil(props.booking) && (!_.isEmpty(props.booking.extendedProps.partnerMapping) || !_.isEmpty(props.booking.partnerMapping))
        const bookingData = {
            groupIds: _.map(allGroups, (g) => g.gid || g.groupId || g.id),
            booking: bookings[0],
            bookings: bookings,
            bookingColor: data.color || getDefaultColor(t),
            addons: addons,
            notifyUser: data.notifyUser,
            paymentMethod: data.paymentMethod,
            repeatConfig: repeatConfig,
            repeatChanges: repeatChanges,
            notes: data.notes,
            useCustomGroupRate: formData.useCustomGroupRate,
            taxAmount: calcCost.taxAmount,
            splitBooking: data.splitBooking,
            updateLinked: data.updateLinked || false,
            updateLinkedNotes: data.updateLinked || false,
            updateInvoice: data.updateInvoice,
            updateAgreement: data.updateAgreement,
            preTax: calcCost.preTax,
            total: calcCost.total,
            ccFee: calcCost.fees,
            updateReason: data.updateReason,
            partnerMappingExists: partnerMappingExists,
        };
        
        if (!_.isNil(data.pushToPartner)) {
            bookingData['pushToPartner'] = data.pushToPartner
        } else {
            if (isEditing && partnerMappingExists) {
                bookingData['pushToPartner'] = true
            }
        }
        if (!_.isNil(data.notifyPartnerTeam)) {
            bookingData['notifyPartnerTeam'] = data.notifyPartnerTeam
        }
        if (showOverlap) {
            bookingData["overlapChoices"] = _.map(overlapOptions, (op) => {
                return {
                    overlapStartTime: op.overlapStartTimeLocal,
                    cancelBookingId: op.bookingId,
                    choice: op.choice
                }
            })
        }
        if (bookingPaymentMethod) {
            if (settings.paymentProcessor === "STRIPE") {
                bookingData["paymentData"] = {
                    stripe: bookingPaymentMethod
                }
            } else if (settings.paymentProcessor === "BEANSTREAM") {
                bookingData["paymentData"] = {
                    bambora: {
                        ...bookingPaymentMethod,
                    }
                }
            } else if (settings.paymentProcessor === "SPORTSPAY") {
                bookingData["paymentData"] = {
                    sportsPay: {
                        ...bookingPaymentMethod,
                    }
                }
            }
            bookingData["paymentMethod"] = getPaymentProcessorName(settings.paymentProcessor).toLowerCase();
        }
        if (data.paymentReference) {
            bookingData["paymentReference"] = data.paymentReference
        }
        if (isEditing) {
            if (bookingData["paymentMethod"] === "paidCreditOnline") {
                bookingData["paymentMethod"] = getPaymentProcessorName(settings.paymentProcessor).toLowerCase();
            }

            const bookingResult = await serverPatch(getApiUrl(`/bookings/${bookingData.booking.id}`), bookingData, {}, onError);
            if (bookingResult) {
                props.onClose(true);
            }
        } else {
            const bookingResult = await serverPost(getApiUrl('/bookings'), bookingData, {}, onError);
            if (bookingResult) {
                props.onClose(true);
            }
        }
        notifyEvent('bookings');
    }

    const onEndChange = (event) => {
        event.stopPropagation();
        setRepeatEndType(event.target.value);
    }

    const onAddNewBookingRow = (newVenue=null, currentIndex) => {
        updateNumberOfBookingRows(rowRefs.current.length + 1);
        setInitialFields(prevInitialFields => {
            const newInitialFields = {...prevInitialFields};
            const formData = formRef.current.getFormData();
            let venueId = formData["0"].venueId;
            let eventTypeId = formData["0"].eventTypeId;
            if (newVenue) {
                venueId = newVenue.id;
                const currentEventType = _.find(eventTypes, (et) => String(et.id) === String(eventTypeId));
                if (currentEventType) {
                    const newEventType = _.find(eventTypes, (et) => String(et.eventType) === String(currentEventType.eventType) && (String(et.rinkId) === String(newVenue.id) || _.includes(et.inheritedToVenueIds, newVenue.id)));
                    if (newEventType) {
                        eventTypeId = newEventType.id;
                    } else {
                        eventTypeId = null;
                    }
                }
            }
            newInitialFields[String(currentIndex)] = {
                venueId: venueId,
                start: moment(targetDate + " " + formData["0"].startTime),
                end: moment(targetDate + " " + formData["0"].endTime),
                startTime: formData["0"].startTime,
                endTime: formData["0"].endTime,
                rate: formData["0"].rate,
                rateType: formData["0"].rateType,
                taxRatePercent: formData["0"].taxRatePercent,
                bPadding: formData["0"].bPadding,
                eventName: formData["0"].eventName,
                description: formData["0"].description,
                eventTypeId: eventTypeId
            }
            return newInitialFields;
        })
    }

    const onAddNewAddonRow = (newVenue=null) => {
        updateNumberOfAddonRows(numberOfAddons + 1);
        setInitialFields(prevInitialFields => {
            const newInitialFields = {...prevInitialFields};
            newInitialFields["addons"] = [...newInitialFields["addons"]];
            newInitialFields["addons"][String(numberOfAddons)] = {
                addonId: null,
                count: 1
            }
            return newInitialFields;
        })
    }

    const showAddonAddition = () => {
        if (numberOfAddons === 0) {
            onAddNewAddonRow();
        }
        setShowAddons(true);
    }

    const onPairedVenueClick = (pairedVenue) => {
        onAddNewBookingRow(pairedVenue, numberOfBookingRows)
    }

    const onAllPairedVenueClick = () => {
        const remainingPairedVenues = _.filter(pairedVenues, (pv) => !_.includes(selectedVenueIds, pv.id));
        let currentIndex = numberOfBookingRows;
        _.each(remainingPairedVenues, (pairedVenue) => {
            onAddNewBookingRow(pairedVenue, currentIndex)
            currentIndex += 1;
        })
    }

    const onDeleteBookingRow = (index) => {
        setInitialFields(prevInitialFields => {
            const newInitialFields = {...prevInitialFields};
            for (let i = index; i < numberOfBookingRows - 1; i++) {
                newInitialFields[i] = newInitialFields[i+1];
            }
            delete newInitialFields[numberOfBookingRows-1];
            return newInitialFields;
        })
        updateNumberOfBookingRows(numberOfBookingRows - 1);
    }

    const onDeleteAddonRow = (index) => {
        setInitialFields(prevInitialFields => {
            const newInitialFields = {...prevInitialFields};
            newInitialFields["addons"] = [...newInitialFields["addons"]];
            newInitialFields["addons"].splice(index, 1);
            return newInitialFields;
        })
        updateNumberOfAddonRows(numberOfAddons - 1);
        if (numberOfAddons - 1 === 0) {
            setShowAddons(false);
        }
    }

    const getDescriptionForRepeatBookings = () => {
        const repeatConfig = initialFields.repeatConfig;
        if (!repeatConfig) {
            return "No"
        } else {
            let endString = "";
            if (repeatConfig.endDate) {
                endString = `Until ${ moment(repeatConfig.endDate).format("ddd, DD MMMM, YYYY") }`;
            } else {
                endString = `${ repeatConfig.occurrence } times starting from ${ moment(repeatConfig.startDate).format("ddd, DD MMMM, YYYY") }`;
            }
            if (repeatConfig.repeatType === "week") {
                const dayNames = _.map(repeatConfig.on, (i) => getNameForDayOfWeek(t, i))
                return `Weekly on ${ dayNames.join(", ")}, ${ endString }`;
            } else if (repeatConfig.repeatType === "day") {
                const repeatString = repeatConfig.repeatInterval > 1 ? `${ repeatConfig.repeatInterval } days`: `day`;
                return `Every ${ repeatString }, ${ endString }`;
            } else if (repeatConfig.repeatType === "month") {
                const repeatString = repeatConfig.repeatInterval > 1 ? `${ repeatConfig.repeatInterval } months`: `month`;
                return `Every ${ repeatString }, ${ endString }`;
            }
        }
    }

    const onAddRepeatDate = () => {
        setNumberOfRepeatRows(numberOfRepeatRows + 1);
    }

    const renderRepeat = () => {
        if (isEditing && !_.isEmpty(initialFields.repeatChanges) && !_.isEmpty(initialFields.repeatChanges.modifyRepeatConfig)) {
            const updateRepeatOptions = [
                { value: "no", label: "No" },
                { value: "all", label: "All" },
                { value: "following", label: "Following" },
                { value: "previous", label: "Previous" },
                { value: "dateRange", label: "Date Range" },
            ]
            return (
                <Row className={classnames(currentTab === "repeat" ? "": "hide")}>
                    <div className="repeat-info">
                        { getDescriptionForRepeatBookings() }
                    </div>
                    <BaseForm.Input colSpan="12" type="select" name="repeatChanges.modifyRepeatConfig.option" label="Update Repeats"
                                    options={updateRepeatOptions} showSearch={false} />
                    {
                        repeatChangeOption === "dateRange" &&
                            <>
                                <BaseForm.Input colSpan="6" type="date" name="repeatChanges.modifyRepeatConfig.dateStartTimeLocal" label="Start Date" required/>
                                <BaseForm.Input colSpan="6" type="date" name="repeatChanges.modifyRepeatConfig.dateEndTimeLocal" label="End Date" required/>
                            </>
                    }
                </Row>
            )
        } else {
            return (
                <Row className={classnames(currentTab === "repeat" ? "": "hide")}>
                    <BaseForm.Input colSpan={12} name="repeatConfig.type" label={t('forms.repeat')} type="select" options={repeatTypeOptions} showSearch={false} />
                    {
                        repeatType !== "none" && repeatType !== "specific" &&
                            <>
                                <BaseForm.Input colSpan="12" type="select" name="repeatConfig.interval" label="Repeat Every" rightContent={repeatType === "day" ? "Days" : (repeatType === "week" ? "Weeks": "Months")}
                                    options={_.map(_.range(1, 31), (i) => { return {value:i, label:i} })} />
                                {
                                    repeatType === "week" &&
                                        <BaseForm.Input colSpan="12" type="multi-select" name="repeatConfig.on" label="Repeats On (Days of the week)"
                                            options={repeatOnOptions} idField="value" />
                                }
                                <BaseForm.Input colSpan="4" type="date" name="repeatConfig.startDate" label="Starts On" />
                                <BaseForm.Input colSpan="4" type="number" name="repeatConfig.endsAfter" label="Ends After"
                                    rightContent="Occurrences" leftContent={<input type="radio" name="test" value="counter" checked={repeatEndType==="counter"} onChange={onEndChange}/>}
                                    required={repeatEndType==="counter"} min="1" validations={{min:1}}/>
                                <BaseForm.Input colSpan="4" type="date" name="repeatConfig.endDate" label="Ends On" leftContent={<input type="radio" name="test" value="date" checked={repeatEndType==="date"} onChange={onEndChange}/>} required={repeatEndType==="date"}/>
                            </>
                    }
                    {
                        repeatType === "specific" &&
                            <>
                                <p>Select the specific dates that you want the booking to repeat. Do not include the current booking date</p>
                                {
                                    _.range(numberOfRepeatRows).map((r, i) =>
                                        <React.Fragment key={i}>
                                            <BaseForm.Input
                                                colSpan="6" type="date" name={`repeatConfig.dates_${r}`}
                                                label={`Date ${i+1}`} minDate={targetDate} placeholder={"Leave blank to ignore"} />
                                        </React.Fragment>
                                    )
                                }
                                <Col md="6" className="d-flex flex-row align-items-center">
                                    <div>
                                        <Button variant="outline-primary" size="sm" onClick={onAddRepeatDate}><i className="fa fa-plus"/> Add Date</Button>
                                    </div>
                                </Col>
                            </>
                    }
                </Row>
            );
        }
    };

    const renderNotes = () => {
        return (
            <Row className={classnames(currentTab === "notes" ? "": "hide")}>
                <BaseForm.Input colSpan={12} name={'notes'} className="large" label={t('forms.notes')} type={'textarea'} placeholder={t('forms.notes.placeholder')} />
            </Row>
        );
    };

    const getLogActionDescription = (log) => {
        let icon = <span/>;
        let actionName = "";
        if (log.action === "add") {
            icon = <i className="fa fa-book"/>
            actionName = "Created";
        } else if (log.action === "update") {
            icon = <i className="fa fa-edit"/>
            actionName = "Updated";
        } else if (log.action === "cancel") {
            icon = <i className="fa fa-cancel"/>
            actionName = "Cancelled";
        } else {
            console.log("The log is " + JSON.stringify(log));
        }
        return (
            <>{ icon } <span>{ actionName } : {moment(log.changedDateLocal).format("MMM D, YYYY - hh:mma")}</span></>
        );
    }

    const renderChanges = () => {
        return (
            <div className={classnames((currentTab === "changes" || currentTab === "paid") ? "": "hide")}>
                <p style={{ fontStyle: "italic" }}>All timezones are shown in the facility's timezone.</p>
                <Table>
                    <thead>
                        <tr>
                            <th>Booking Action</th>
                            <th>Time Slot</th>
                            <th>Total</th>
                            <th>Payment</th>
                            <th>Admin</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        _.map(logs, (log, i) =>
                            <tr key={i}>
                                <td>{ getLogActionDescription(log) }</td>
                                <td>{ `${moment(log.startTimeLocal).format("ddd, MMM D, YYYY ")}${conciseTimeDisplay(moment(log.startTimeLocal))} - ${conciseTimeDisplay(moment(log.endTimeLocal))}` } ({ log.venueName })</td>
                                <td>{ currencyFormat(log.cost) }</td>
                                <td>{ `${log.paymentMethod}${log.paymentMethod === "Credit Card Online" ? ` (Fee: ${currencyFormat(log.ccFees, true)})` : ""}` }</td>
                                <td>{ log.changedByName }</td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </div>
        );
    };

    const onOverlapChoiceSelected = (index, choice) => {
        setOverlapOptions(prevOptions => {
            const newOptions = {...prevOptions};
            newOptions[index].choice = choice;
            if (!_.isEmpty(newOptions[index].linkedBookingIds)) {
                _.each(newOptions, (op, a) => {
                    if (_.includes(newOptions[index].linkedBookingIds, op.bookingId)) {
                        newOptions[a].choice = choice;
                    }
                })
            }
            return newOptions;
        });
    }

    const onCancelOverlap = () => {
        setOverlapOptions([]);
        setShowOverlap(false);
        setCurrentTab("details");
    }

    const renderOverlap = () => {
        const overlapChoices = [
            { id: "yes" , label: "Yes" },
            { id: "no" , label: "No" },
            { id: "cancel" , label: "Cancel" },
            { id: "cancelNotify" , label: "Cancel & Notify" },
        ];
        return (
            <Row className={classnames(currentTab === "overlap" ? "": "hide")}>
                <Col md="12">
                    <Button variant="alink" onClick={onCancelOverlap}>Cancel & Back to Booking</Button>
                </Col>
                <Table>
                    <thead>
                        <tr>
                            <th>Overlap</th>
                            <th>Group</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Venue</th>
                            <th>Timeslot</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        _.map(overlapOptions, (op, i) =>
                            <tr key={i}>
                                <td>
                                    <SingleSelectDropdown items={overlapChoices} showSearch={false}
                                        selectedId={overlapOptions[i].choice || (settings.overlapDefaultNo ? "no": "yes")}
                                        onSelect={(choice) => onOverlapChoiceSelected(i, choice)} />
                                </td>
                                <td>{ op.groupName }</td>
                                <td>{ op.name }</td>
                                <td>{ op.phone }</td>
                                <td>{ op.email }</td>
                                <td>{ op.venueName }</td>
                                <td>{ op.startTimeLocal }</td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </Row>
        );
    };

    const createClass = () => {
        if (!formRef.current) {
            return;
        }
        const formData = formRef.current.getFormData();
        navigate(`/${facilityLink}/class/create?d=${moment(targetDate).format("YYYY-MM-DD")}&v=${formData["0"].venueId}&ev=${formData["0"].eventTypeId}&s=${formData["0"].startTime}&e=${formData["0"].endTime}`);
    }

    const isRepeating = (!_.isNil(props.booking) && !_.isNil(props.booking.extendedProps)) ? props.booking.extendedProps.isRepeat : false;
    const tabItems = [
        { value: "details", label: t('tabs.booking_modal.details'), icon: "fa-book", active: currentTab === "details", onClick: () => setCurrentTab("details") },
        { value: "repeat", label: t('tabs.booking_modal.repeat'), icon: classnames("fa-retweet", isRepeating ? "fa-c-success": ""), active: currentTab === "repeat", onClick: () => setCurrentTab("repeat") },
        { value: "notes", label: t('tabs.booking_modal.notes'), icon: classnames("fa-comment", initialFields.notes ? "fa-c-success": ""), active: currentTab === "notes", onClick: () => setCurrentTab("notes") },
        isEditing && { value: "changes", label: t('tabs.booking_modal.changes'), icon: "fa-edit", active: currentTab === "changes", onClick: () => setCurrentTab("changes") },
        showOverlap && { value: "overlap", label: t('tabs.booking_modal.overlap'), icon: classnames("fa-comment", !_.isNil(initialFields.notes) ? "fa-c-success": ""), active: currentTab === "overlap", onClick: () => setCurrentTab("overlap") },
    ];

    const tabRightItems = [
        !_.isNil(invoice) ? { value: "invoice", label: GetShortedName(invoice.invoiceNumber), icon: "fa-file", link: `/${facilityLink}/invoice/view?uuid=${invoice.uuid}` }: null,
        !_.isNil(agreement) ? { value: "agreement", label: GetShortedName(agreement.agreementNumber), icon: "fa-file", link: `/${facilityLink}/agreement/accept?uuid=${agreement.uuid}` }: null,
        isPaid ? { value: "paid", label: t('tabs.booking_modal.paid'), icon: "fa-circle-check fa-c-success", active: currentTab === "paid", onClick: () => setCurrentTab("paid") }: null,
        !isEditing && settings.allowClasses ? { value: "class", label: t('tabs.booking_modal.create_class'), icon: "fa-users", onClick: () => createClass() }: null,
    ]

    let originalVenue = null;
    if (originalVenueId) {
        originalVenue = _.find(venues, (v) => v.id === originalVenueId);
    }
    const filteredPaymentMethods = getFilteredPaymentMethods(paymentMethods, settings, originalVenue, "admin");
    const modalTitle = isEditing ? t('booking_modal.edit_booking', { bookingId: editingBookingId }): t('booking_modal.new_booking');
    let partnerPrimaryGroupSelected = false;
    if (primaryGroup && !_.isEmpty(primaryGroup.partnerMappings)) {
        partnerPrimaryGroupSelected = _.includes(partnerSitesSelected, primaryGroup.partnerMappings[0].siteId)
    }
    const isPartnerOrSuperAdmin = settings.isPartnerFacility && (userInfo.isSuperAdmin || isFullAdmin(userInfo));
    const showPartnerActions = partnerPrimaryGroupSelected && partnerVenueSelected && isPartnerOrSuperAdmin;
    const partnerGroupsAlreadySelected = isEditing && isPartnerOrSuperAdmin && !_.isNil(props.booking) && !_.isNil(props.booking.extendedProps) && !_.isEmpty(props.booking.extendedProps.partnerMapping);
    const selectedPaymentMethod = paymentMethod && _.find(paymentMethods, pm => pm.paymentTag === paymentMethod);
    const types = _.map(paymentMethods, pm => pm.paymentType);
    return (
        <BaseModal {...props} size="lg">
        {
            () =>
                <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={addBooking} ref={formRef} errorFields={error}>
                    <BaseModal.Header>
                        <BaseModal.Title>{modalTitle} - <span className="booking-date-wrapper" onClick={onDateClick}>{moment(targetDate).format("ddd, MMM DD, YYYY")}&nbsp;&nbsp;<i className="fa fa-calendar"/></span></BaseModal.Title>
                    </BaseModal.Header>
                    <div id="pop-date" className="pop-date" style={{ width:"221px", margin: "-12px auto 0 auto", position:"absolute", marginLeft: `${datePickerMarginLeft}px` }}/>
                    <TabHeader items={tabItems} rightItems={tabRightItems} rightClassName="hide-md" hideMore={true} dontResize />
                    <BaseModal.Body>
                        <div className={classnames(currentTab === "details" ? "": "hide")}>
                            <BookingGroupsSelector ref={groupSelectorRef} name="groups" label={t('forms.group_selection')} required max={!_.isNil(originalVenue) && originalVenue.noOfGroups} showGroupDetailsList={true} disabled={partnerGroupsAlreadySelected}/>
                            {
                                groupError && <div className="form-error-message">{ groupError }</div>
                            }
                            <div className="paired-venues">
                                {
                                    !_.isEmpty(pairedVenues) &&
                                        <Button variant="success" size="sm" onClick={onAllPairedVenueClick}><i className="fa fa-plus"/> {t('booking_modal.add_all')}</Button>
                                }
                                {
                                    _.map(pairedVenues, (pvi, i) =>
                                        <Button variant="primary" key={i} size="sm" onClick={() => onPairedVenueClick(pvi)} disabled={_.includes(selectedVenueIds, pvi.id)}>{ pvi.name }</Button>
                                    )
                                }
                            </div>
                            {
                                _.range(numberOfBookingRows).map((r, i) =>
                                    <BookingRow key={i} ref={rowRefs.current[r]} prefix={`${r}.`}
                                        showAddNew={i === 0 && !isEditing} canDelete={i !== 0}
                                        onAddNew={(event) => onAddNewBookingRow(null, numberOfBookingRows)}
                                        usingCustomRate={useCustomGroupRate}
                                        isAdminBooking={true}
                                        eventTypes={eventTypes}
                                        resetEventTypes={resetEventTypes}
                                        onDeleteRow={() => onDeleteBookingRow(i)}
                                    />
                                )
                            }
                            {
                                hasBookingsInPast &&
                                    <Alert variant="danger"><i className="fa fa-exclamation-triangle"/> {t('booking_modal.warning_in_the_past')}</Alert>
                            }
                            {
                                hasDifferentStandardRate &&
                                    <Alert variant="warning"><i className="fa fa-exclamation-triangle"/> {t('booking_modal.warning_standard_rate_changed')}</Alert>
                            }
                            <Row>
                            {
                                (initialFields.groups && initialFields.groups.length > 1 && originalVenue && originalVenue.allowSplitBooking) ?
                                    <Col md="4">
                                        <BaseForm.Input type="check" name="splitBooking" label={<span><i className="fa fa-file"/> {t('booking_modal.split_booking')}</span>} />
                                    </Col>
                                : <Col md="4"/>
                            }
                                <Col md="4"/>
                            {
                                (primaryGroup && !_.isNil(primaryGroup.rate)) ?
                                    <Col md="4">
                                        <BaseForm.Input type="check" name="useCustomGroupRate" label={<span><i className="fa fa-user"/> {t('booking_modal.customer_rate')}: ${primaryGroup.rate}/HR</span>} />
                                    </Col>
                                : <Col md="4"/>
                            }
                            </Row>
                            {
                                paymentMethod === "paidCreditOnline" && paymentMethod !== originalPaymentMethod && settings.paymentProcessor !== "NONE" &&
                                    <Row className="cc_card_input">
                                        <Col md="12">
                                            <BookingPaymentInput
                                                ref={paymentRef} prefix="ccOnline" groupId={primaryGroup && (primaryGroup.gid || primaryGroup.groupId)}
                                                total={calcCost && calcCost.total} setInitialFields={setInitialFields}/>
                                            {
                                                !_.isNil(error) && !_.isNil(error.card) &&
                                                    <span className="form-error-message">{ error.card }</span>
                                            }
                                        </Col>
                                    </Row>
                            }
                            {
                                selectedPaymentMethod && selectedPaymentMethod.isPayment && paymentMethod !== "paidCreditOnline" &&
                                    <Row>
                                        <BaseForm.Input colSpan="6" type="text" name="paymentReference" label={t('booking_modal.payment_reference')} />
                                    </Row>
                            }
                            {
                                !showAddons && allowAddons &&
                                    <Row>
                                        <Col md="12">
                                            <Button variant="alink" className="skinny" onClick={() => showAddonAddition()}>Include add-ons</Button>
                                        </Col>
                                    </Row>
                            }
                            {
                                showAddons &&
                                    <Row>
                                        <Col md="12">
                                            <strong>Add ons</strong><br/>
                                            {
                                                numberOfBookingRows > 1 &&
                                                    <span style={{ fontStyle: "italic" }}>These addons will be added to each of the booking above.</span>
                                            }
                                            {
                                                _.range(numberOfAddons).map((r, i) =>
                                                    <BookingAddonRow key={i} ref={addonsRowRefs.current[r]} prefix={`addons.${r}.`}
                                                        showAddNew={i === 0 && !isEditing} canDelete={true}
                                                        onAddNew={(event) => onAddNewAddonRow()}
                                                        addons={addons}
                                                        onDeleteRow={() => onDeleteAddonRow(i)}
                                                    />
                                                )
                                            }
                                        </Col>
                                    </Row>
                            }
                        </div>
                        { renderRepeat() }
                        { renderNotes() }
                        { renderChanges() }
                        { renderOverlap() }
                        {
                            error && error.message &&
                                <span className="form-error-message">{ error.message }</span>
                        }
                    </BaseModal.Body>
                    <BaseModal.Footer>
                        <Container>
                            {
                                showPartnerActions &&
                                    <Row>
                                        <BaseForm.Input
                                            colSpan="5" name="pushToPartner"  type="check"
                                            label={<span>
                                                <Image src={"/images/la_logo.png"} width="15px" style={{marginRight: "5px"}}/> { isEditing ? "Sync to LeagueApps": "Sync to LeagueApps" }
                                            </span>}
                                        />
                                        {
                                            isEditing &&
                                            <BaseForm.Input
                                                colSpan="5" name="notifyPartnerTeam"  type="check"
                                                label={<span>
                                                    <Image src={"/images/la_logo.png"} width="15px" style={{marginRight: "5px"}}/> Notify Team Members
                                                </span>}
                                            />
                                        }
                                    </Row>
                            }
                            {
                                isEditing &&
                                    <Row>
                                        {
                                            !_.isNil(props.booking.extendedProps.linkedId) && !props.booking.extendedProps.repeatInfo &&
                                                <BaseForm.Input colSpan="3" name="updateLinked" label={<span><i className="fa fa-linked"/> {t('booking_modal.update_linked')}</span>} type="check" />
                                        }
                                        {
                                            props.booking.extendedProps.invoice &&
                                                <BaseForm.Input colSpan="3" name="updateInvoice" label={t('booking_modal.update_invoice')} type="check" />
                                        }
                                        {
                                            props.booking.extendedProps.agreement &&
                                                <BaseForm.Input colSpan="3" name="updateAgreement" label={t('booking_modal.update_agreement')} type="check" />
                                        }
                                    </Row>
                            }
                            {
                                notifyUser && isEditing &&
                                <Row>
                                    <BaseForm.Input
                                        colSpan="12" name="updateReason" label={t('forms.update_reason')} type="text"
                                        placeholder={"Message to the customer"}
                                    />
                                </Row>
                            }
                            <Row>
                            {
                                (originalVenue && originalVenue.allowBookingColor) ?
                                    <BaseForm.Input colSpan="1" name="color" label={<span><i className="fa fa-link"/> {t('forms.color')}</span>} hideLabel type="color" />
                                : <Col md="1"/>
                            }
                                <BaseForm.Input colSpan="2" name="notifyUser" label={t('forms.notify')} type="check" />
                                <BaseForm.Input colSpan="3" name="paymentMethod" label={t('forms.payment_method')} type="select"
                                    options={filteredPaymentMethods} idField="paymentTag" labelField="paymentType"
                                    showSearch={false} />
                                <BaseForm.Input colSpan="4" name="costDescription" label={`${t('booking_modal.total_cost')}${(initialFields.paymentMethod === "paidCreditOnline" && calcCost && calcCost.fees) ? ` (${t('booking_modal.cost')} + ${t('booking_modal.tax')} + ${settings.creditCardFeeLabel})` : ``}`} type="text" readOnly={true} />
                                <div className="col-md-2 d-flex align-items-center">
                                    <SubmitButton disabled={_.isEmpty(calcCost)} className="btn-complete">{isEditing ? t('booking_modal.update_now') : t('booking_modal.book_now')}</SubmitButton>
                                </div>
                            </Row>
                            {
                                paymentMethod === "requestCC" && (!isEditing || (isEditing && originalPaymentMethod !== paymentMethod)) &&
                                    <Row>
                                        <Col md="12">
                                            <span className="subtext error">Please note that an email will be sent to the group to request payment for the booking. Only one email will be sent in any given 24 hours window.</span>
                                        </Col>
                                    </Row>
                            }
                        </Container>
                    </BaseModal.Footer>
                </BaseForm>
            }
        </BaseModal>
    );

}

export default AddOrEditBookingModal;
