import '../App.scss';
import '../css/modals.scss';
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, createRef, useMemo } from 'react';
import {BaseContext, getStripePublishableKeyForFacility, sleep} from '../helpers/common';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
const _ = require('lodash');

const StripePaymentInput = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data) {
            await onSubmit(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        }
    }));

    const { t } = useTranslation('common');
    const { settings } = useContext(BaseContext);
    const [paymentSource, setPaymentSource] = useState(null);
    const [total, setTotal] = useState(props.total);
    const formRef = createRef();

    useEffect(() => {
        setPaymentSource(props.paymentSource);
    }, [props.paymentSource])

    useEffect(() => {
        setTotal(props.total)
    }, [props.total]);

    const onSubmit = async (data) => {
        if (paymentSource) {
            const paymentMethodData = {
                paymentMethodId: paymentSource
            }
            if (props.onPay) {
                await props.onPay(paymentMethodData);
            }
        } else if (formRef.current) {
            await formRef.current.onSubmit(data);
        }
    }

    const onFieldChange = (name, value) => {
    }

    const onPay = async (paymentMethod) => {
        const paymentMethodData = {
            paymentMethodId: paymentMethod.id
        }
        if (props.onPay) {
            await props.onPay(paymentMethodData);
        }
    }

    const stripePromise = useMemo(() => {
        return loadStripe(getStripePublishableKeyForFacility(settings));
    }, [settings]);
    const amount = Math.floor(parseFloat(total)*100);
    const options = {
        mode: 'payment',
        amount: amount === 0 ? 1 : amount,
        currency: settings.currency.toLowerCase(),
        paymentMethodCreation: "manual",
        appearance: {/*...*/},
        paymentMethodTypes: ["card"]
    };

    return (
        <>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm ref={formRef} onPay={onPay} paymentSource={paymentSource}/>
            </Elements>
        </>
    );

})

const CheckoutForm = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data) {
            await onSubmit(data);
        },
    }));

    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [paymentSource, setPaymentSource] = useState(null);

    useEffect(() => {
        setPaymentSource(props.paymentSource);
    }, [props.paymentSource])

    const onSubmit = async (data) => {
        if (!stripe || !elements) {
            return;
        }

        const {error: submitError} = await elements.submit();
        if (submitError) {
            setErrorMessage(submitError.message);
        }

        const {paymentMethod, error} = await stripe.createPaymentMethod({
            elements,
        });

        if (error) {
            setErrorMessage(error.message);
        } else {
            if (props.onPay) {
                await props.onPay(paymentMethod);
            }
        }
    };

    return (
        <>
            {
                _.isNil(paymentSource) &&
                    <PaymentElement />
            }
            {errorMessage && <div>{errorMessage}</div>}
        </>
    )
})

export default StripePaymentInput;
