import { useEffect, useContext, useState } from 'react';
import '../../App.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import { serverPost, getUrl, serverFetch } from '../../helpers/server';
import { storageLogout, storeUserInfo } from '../../helpers/storage';
import { getFacilityName, BaseContext } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Button, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const _ = require("lodash");


function MergeUsers() {
    const { getApiUrl } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [users, setUsers] = useState([]);
    const [userIds, setUserIds] = useState([]);

    useEffect(() => {
        document.title = "User Merge";
    }, []);

    const onSubmit = (data) => {
        serverPost(getApiUrl("/users/search"), data).then((res) => {
            setUsers(res);
            setUserIds(_.map(res, user => { 
                const userName = _.join(_.filter([user.firstName, user.lastName], a => !!a), " ");
                return {"id": user.id, "label": userName} 
            }));
        });
    }

    const onMerge = async (data) => {
        await serverPost(getApiUrl("/users/merge"), data);
        serverPost(getApiUrl("/users/search"), {"email": users[0].email}).then((res) => {
            setUsers(res);
            setUserIds(_.map(res, user => { 
                const userName = _.join(_.filter([user.firstName, user.lastName], a => !!a), " ");
                return {"id": user.id, "label": userName} 
            }));
        });
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "super-admin")}/>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-md-4">
                            <BaseForm onSubmit={onSubmit}>
                                <Row>
                                    <BaseForm.Input colSpan={12} name={'email'} label="Email" type={'text'} required />
                                </Row>
                                <SubmitButton className="btn-complete">Find Users</SubmitButton>
                            </BaseForm>
                        </div>
                    </Row>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Facility ID</th>
                                <th>Facility Name</th>
                                <th>User ID</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Access Level</th>
                                <th>User Groups</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(users, (user, i) =>
                                    <tr key={i}>
                                        <td>{user.facilityId}</td>
                                        <td>{facilityLink}</td>
                                        <td>{user.id}</td>
                                        <td>{user.firstName}</td>
                                        <td>{user.lastName}</td>
                                        <td>{user.accessLevel}</td>
                                        <td>{user.userGroups.map(group => {
                                            return <>{group.name + " "}<br /></>;
                                        })}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-md-4">
                            <BaseForm onSubmit={onMerge}>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Merge User</th>
                                        <th>Into User</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td width="30%"><BaseForm.Select idField={"id"} name="userFromId" label="Merge user: " options={userIds} hideLabel/></td>
                                    <td width="30%"><BaseForm.Select idField={"id"} name="userToId" label="Into user: " options={userIds} hideLabel/></td>
                                    <td width="30%"><SubmitButton className="btn-complete">Merge Users</SubmitButton></td>
                                </tr>
                                </tbody>
                                </Table>
                            </BaseForm>
                        </div>
                    </Row>
                </div>
            </div>
        </BaseContainer>
    );
}

export default MergeUsers;
