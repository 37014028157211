import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import DeleteButton from '../../components/DeleteButton';
import BaseForm from '../../components/BaseForm';
import Notification from '../../components/Notification';
import BaseContainer from '../../components/Container';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import TabHeader from '../../components/TabHeader';
import SubmitButton from '../../components/SubmitButton';
import { serverFetch, serverPost, serverPatch, serverDelete } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function AgreementTemplates() {
    const { getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [agreementTemplates, setAgreementTemplates] = useState([]);
    const [currentAgreementTemplate, setCurrentAgreementTemplate] = useState(null);

    useEffect(() => {
        document.title = "Agreement Templates - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl('/agreement_templates'), { skipCache }).then((res) => {
            setAgreementTemplates(res);
            setCurrentAgreementTemplate((previous) => {
                if (previous) {
                    return previous;
                } else if (res.length > 0) {
                    return res[0].id;
                }
                return previous;
            });
        })
    }

    const updateTemplate = (template, data) => {
        if (template.isNew) {
            // Adding a new template
            serverPost(getApiUrl('/agreement_templates'), data).then((res) => {
                if (res) {
                    Notification.Show(t('setting.agr_temp.successfully_created'));
                }
                fetchData(true);
                setCurrentAgreementTemplate(res.id);
            });
        } else {
            // Updating an existing template
            serverPatch(getApiUrl(`/agreement_templates/${template.id}`), data).then((res) => {
                if (res) {
                    Notification.Show(t('setting.agr_temp.successfully_updated'));
                }
                fetchData(true);
            });
        }
    }

    const deleteTemplate = (template) => {
        if (template.isNew) {
            // A temporary template, just remove it from the list.
            const filteredTemplates = _.filter(agreementTemplates, (t) => String(t.id) !== String(template.id));
            setAgreementTemplates(filteredTemplates);
            setCurrentAgreementTemplate(!_.isEmpty(filteredTemplates) ? filteredTemplates[0].id : null);
        } else {
            // Updating an existing template
            serverDelete(getApiUrl(`/agreement_templates/${template.id}`)).then((res) => {
                if (res) {
                    Notification.Show(t('setting.agr_temp.successfully_deleted'));
                }
                setCurrentAgreementTemplate(null);
                fetchData(true);
            });
        }
    }

    const onAddNew = () => {
        setAgreementTemplates((previousTemplates) => {
            const newTemplates = [...previousTemplates];
            const prefix = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
            newTemplates.push({
                isNew: true,
                id: 'new_'+prefix,
                name: t('setting.agr_temp.new_template'),
                opening: '',
                contract: ''
            });
            setCurrentAgreementTemplate('new_'+prefix);
            return newTemplates;
        });
    }

    const templateOptions = _.map(agreementTemplates, (template) => { return { id: template.id, label: template.name || t("common.none") } });
    const selectedTemplate = _.find(agreementTemplates, (t) => String(t.id) === String(currentAgreementTemplate));

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "inv-agr")}/>
            <div className="content-box">
                <div className="content-header">
                    {t("setting.agr_temp.agr_templates")}
                </div>
                <div className="content-body">
                    <p>{t('setting.agr_temp.description')}</p>
                    <br/>
                    <Row>
                        <Col md="5" className="d-flex align-items-center">
                            <SingleSelectDropdown name="template" label={t("setting.agr_temp.select_temp")} items={templateOptions}
                                selectedId={currentAgreementTemplate} showSearch={false} onSelect={setCurrentAgreementTemplate} />
                            <Button variant="primary" onClick={onAddNew}><i className="fa fa-circle-plus"/> {t("common.add_new")}</Button>
                        </Col>
                    </Row>
                    <br/>
                    <BaseForm onSubmit={(data) => updateTemplate(selectedTemplate, data)} initialFormFields={selectedTemplate}>
                        <Row>
                            <BaseForm.Input colSpan="12" type="text" name="name" label={t("setting.agr_temp.agr_name")} required />
                            <strong>{t("setting.agr_temp.agr_open_state")}</strong>
                            <BaseForm.Input
                                colSpan="12" type="editor" name="opening"
                                tokens={[
                                    '{companyName}',
                                    '{address1}',
                                    '{groupName}',
                                    '{to}', //Change this to be more understandable ie: CustomerName
                                    '{customerEmail}',
                                    '{customerPhone}',
                                    '{customerAddress}'
                                ]}
                            />
                            <strong>{t("setting.agr_temp.agr_cont")}</strong>
                            <BaseForm.Input colSpan="12" type="editor" name="contract" />
                            <Col md="12" className="text-end">
                                <DeleteButton size="md" onDelete={() => deleteTemplate(selectedTemplate)}>{t("setting.agr_temp.del_temp")}</DeleteButton>
                                <SubmitButton>{t("setting.agr_temp.up_temp")}</SubmitButton>
                            </Col>
                        </Row>
                    </BaseForm>
                </div>
            </div>
        </BaseContainer>
    );
}

export default AgreementTemplates;
